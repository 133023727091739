import React from 'react'
import styled from 'styled-components'
import { customMedia } from '../common/customMedia'
import { Image } from '../common/Image'

export const Policy = () => {
  return (
    <>
      <Container>
        <Wrapper>
          <PrivacyIconWrapper>
            <Image path="privacy@2x.png" />
          </PrivacyIconWrapper>

          <Preamble>
            <Paragraph>
              　株式会社リモハブ（以下「当社」といいます）は、当社が事業を行い又は当社がサービスを提供するために、お客様などの個人に関する情報を取得し、利用させて頂くことがありますが、お客様などの個人に関する情報の取扱いの方針として本プライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
            </Paragraph>
          </Preamble>

          <Article>
            <Title>第１条（適⽤関係）</Title>
            <div>
              １
              当社が、当社ウェブサイト上に掲載するプライバシーポリシーその他の個人情報保護方針または当社が提供するサービス等に関する利用規約などにおける個人情報の取扱いに関する規定と、本ポリシーが矛盾または抵触する場合には、本ポリシーが優先します。
            </div>
            <div>
              ２
              本ポリシーは、当社以外の者が提供するサービスには提供されず、そのサービスにおける個人情報の取扱いについては、そのサービスを提供する事業者が定めるプライバシーポリシーをご参照ください。
            </div>
          </Article>

          <Article>
            <Title>第２条（定義）</Title>
            <div>本ポリシーでは、次の各⽤語は、次の意味を有します。</div>
            <Table>
              <thead>
                <Tr>
                  <Th>用　語</Th>
                  <Th>定　義</Th>
                </Tr>
              </thead>
              <tbody>
                <Tr>
                  <Td>個人情報保護法</Td>
                  <Td>個人情報の保護に関する法律（平成１５年法律第５７号）</Td>
                </Tr>
                <Tr>
                  <Td>法令</Td>
                  <Td>法、政令、規則、基準およびガイドラインなど</Td>
                </Tr>
                <Tr>
                  <Td>個人情報</Td>
                  <Td>個人情報保護法２条１項の「個人情報」</Td>
                </Tr>
                <Tr>
                  <Td>個人データ</Td>
                  <Td>個人情報保護法１６条３項の「個人データ」</Td>
                </Tr>
                <Tr>
                  <Td>保有個人データ</Td>
                  <Td>個人情報保護法１６条４項の「保有個人データ」</Td>
                </Tr>
                <Tr>
                  <Td>統計情報</Td>
                  <Td>
                    複数人の情報から共通要素に係る項目を抽出して同じ分類ごとに集計して得られる情報
                  </Td>
                </Tr>
              </tbody>
            </Table>
          </Article>

          <Article id="article3">
            <Title>第３条（当社が取得する個人情報の利用目的）</Title>
            <Paragraph>
              当社は、次の個人情報を取得し、各利用目的でその個人情報の取扱いを行います。
            </Paragraph>
            <div>１ お客様に関する個人情報</div>
            <div>(1) お客様の本人確認・個人認証</div>
            <div>
              (2)
              お客様のお問い合わせ・ご相談・苦情・修理・サポートへの対応並びに確認及び記録
            </div>
            <div>
              (3)
              ご案内状・電子メール等による商品・サービス・キャンペーン等の広告・情報提供
            </div>
            <div>(4) 業務上必要なご連絡、契約の義務履行、商談等</div>
            <div>
              (5) 当社が提供するサービスの効果測定、当該サービスの改善・改良
            </div>
            <div>(6) 取引先情報の管理</div>
            <div>(7) 学術研究での利用</div>
            <div>(8) 統計情報の作成</div>
            <div>
              (9)
              当社が提供するサービスの質の向上あるいは当該サービスの利用者の健康増進その他当該サービスの利用者の個人情報の利活用のため，当該サービスの利用者のかかりつけ医その他の第三者に提供する目的
            </div>
            <div>
              ２ 採用応募者に関する個人情報
              <br />
              採用応募者への連絡、情報提供、その他採用・募集活動に必要な利用
            </div>
            <div>
              ３ 従業員・退職者に関する個人情報
              <br />
              雇用及び人事労務管理
            </div>
          </Article>

          <Article>
            <Title>第４条（第三者提供）</Title>
            <div>
              １
              当社は、個人情報保護法を含む法令に基づく場合を除き、第三者に対して個人データを提供しません。法令に基づき開示するときは，個人情報保護法その他適用法令を遵守します。
            </div>
            <div>
              ２
              当社は，ご本人の同意その他法令で定められた手続を経て，当社が提供するサービスにおいてご本人のかかりつけ医その他の第三者に個人情報を提供することがあります。
            </div>
            <div>
              ３
              当社は，個人情報を第三者に提供する場合，可能な限り，仮名化等の加工を施したうえで提供するように努めるものとします。
            </div>
            <div>
              ４
              当社は、個人データに該当しない個人に関連する情報を第三者に提供することがあります。その個人に関連する情報の提供先である第三者において、その個人に関連する情報を個人データとして取得することが想定される場合、個人情報保護法に定めるところにより当該第三者がその個人に関連する情報の本人の同意を取得したことを確認したうえで、当該個人に関連する情報を提供します。
            </div>
            <div>
              ５
              当社は、個人データを第三者に提供した場合、個人情報保護法第２９条の規定に基づき第三者提供に係る記録を作成し、保存するものとします。
            </div>
          </Article>

          <Article>
            <Title>第５条（委託）</Title>
            <Paragraph>
              　当社は、利用目的の達成に必要な範囲内において、個人データの全部または一部の取扱いを第三者に委託することがあります。当社は、委託先が、個人情報を適切かつ安全に管理するように監督します。
            </Paragraph>
          </Article>

          <Article>
            <Title>第６条（共同利⽤）</Title>
            <Paragraph>
              　当社は、当社グループ企業その他第三者と共同して事業活動を行うとき、その活動に必要な個人データを共同利用することがあります。このとき、あらかじめ利用目的、個人データの項目、共同して利用する者の範囲および管理責任者を明確にし、本人に通知または公表します。また、当社は、その個人データの共同利用者に、個人情報を適切かつ安全に管理することを確約させます。
            </Paragraph>
          </Article>

          <Article>
            <Title>第７条（安全管理措置）</Title>
            <Paragraph>
              　当社は、個人情報の漏えい、滅失又はき損の防止その他個人情報の安全管理に努め、かつ、そのために十分なセキュリティ対策を講じます。また、当社は、個人情報が適正に取り扱われるように、関連規程を整備し、かつ、従業員を適切に教育及び指導し、その管理態勢を継続的に見直し、改善に努めます。
            </Paragraph>
          </Article>

          <Article>
            <Title>第８条（保有個⼈データの開⽰・訂正などの権利）</Title>
            <div>
              １
              当社が管理する保有個人データについて、ご本人またはその代理人から利用目的の通知の請求または開示、訂正・追加・削除、利用停止・消去もしくは第三者提供の停止の請求（以下、「開示等の請求」と言います。）をされる場合は、第１０条のお問い合わせ窓口に以下の情報をご提供ください。また、本条の規定は、第三者提供記録の開示請求について準用します。
            </div>
            <div>(1) ⽒名</div>
            <div>(2) 住所または居所</div>
            <div>(3) 電話番号</div>
            <div>
              (4)
              法定代理人による請求の場合、法定代理人の氏名、住所または居所、電話番号
            </div>
            <div>
              (5) 開示等の請求をされる保有個人データを特定するに足りる情報
            </div>
            <div>
              ２
              前項の請求がご本人あるいはその法定代理人からの請求であることを当社が確認できた後、当社は個人情報保護法の定めに基づき遅滞なく保有個人データの開示、訂正・追加・削除、利用停止・消去もしくは第三者提供の停止に応じます。ただし、以下のいずれかに該当する場合、当社は開示、訂正・追加・削除、利用停止・消去もしくは第三者提供に応じる義務を負いません。
            </div>
            <div>
              (1) 開示等の請求の対象となる保有個人データが存在しない場合
            </div>
            <div>
              (2)
              個人情報保護法その他の法令により、当社が開示、訂正・追加・削除、利用停止・消去もしくは第三者提供に応じる義務を負わない場合
            </div>
            <div>
              ３
              第１項の請求に基づいて当社が保有個人データを開示するにあたり、１件あたり、１０００円の手数料を銀行振込にて頂戴しておりますので、ご了承ください。
            </div>
          </Article>

          <Article>
            <Title>第９条（本ポリシーの変更）</Title>
            <div>
              １
              法令上、個人情報のご本人の同意が必要となるような内容の変更を行うときは、別途当社が定める方法により、個人情報のご本人の同意を取得します。
            </div>
            <div>
              ２
              前項の変更後の本ポリシーについては、当社ウェブサイトでの掲示その他分かりやすい方法により告知します。
            </div>
          </Article>

          <Article>
            <Title>第１０条（お問い合わせ）</Title>
            <div>
              当社による個人情報の取扱いに関するご意見、ご質問、ご要望その他お問い合わせは、下記の窓口までお願いします。
            </div>

            <div>
              株式会社リモハブ　個人情報相談受付窓口
              <br />
              経営管理部長　小田 泰寛
              <br />
              〒５３２‐００１１
              <br />
              大阪市淀川区西中島4丁目3-24 GATE TERRACE SHIN OSAKA 3F
              <br />
              電話：06-7494-3400
              <br />
              メール：privacy@remohab.com
              <br />
            </div>
          </Article>

          <Signature>
            ２０２０年 4 ⽉ 6 ⽇ 制定
            <br />
            ２０２２年 4 ⽉ 1 ⽇ 改定
            <br />
            ２０２２年 11 ⽉ 1 ⽇ 改定
            <br />
            ２０２３年 5 月 1 日 改定
            <br />
            <br />
            株式会社リモハブ
            <br />
            大阪市淀川区西中島４丁目３‐２４
            <br />
            代表取締役　⾕⼝ 達典
            <br />
          </Signature>
        </Wrapper>
      </Container>
    </>
  )
}

const Container = styled.div`
  ${customMedia.lessThan('medium')`
    padding: 0 20px;
  `}

  background-color: #efefef;
`

const Wrapper = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 1rem;
    padding: 80px 0;
  `}
  ${customMedia.between('medium', 'large')`
    font-size: 2rem;
    margin: 0 auto;
    padding: 160px 0 80px;
    width: 735px;
  `}
  ${customMedia.greaterThan('large')`
    font-size: 2rem;
    margin: 0 auto;
    padding: 160px 0 80px;
    width: 1000px;
  `}

  color: #60656b;
`

const PrivacyIconWrapper = styled.div`
  ${customMedia.lessThan('medium')`
    margin-bottom: 20px;
    width: 137px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-bottom: 50px;
    width: 274px;
  `}
  
  margin: 0 auto;
`

const Preamble = styled.div`
  ${customMedia.lessThan('medium')`
    margin-bottom: 23px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-bottom: 38px;
  `}
`

const Article = styled.div`
  ${customMedia.lessThan('medium')`
    margin-bottom: 27px;
  `}
  ${customMedia.greaterThan('medium')`
    margin-bottom: 72px;
  `}

  div {
    ${customMedia.lessThan('medium')`
      margin-bottom: 20px;
    `}
    ${customMedia.greaterThan('medium')`
      margin-bottom: 40px;
    `}
  }
`

const Title = styled.div`
  ${customMedia.lessThan('medium')`
    font-size: 12.5px;
    margin-bottom: 19px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2.5rem;
    margin-bottom: 38px;
  `}

  color: #26d2ad;
  font-weight: bold;
`

const Paragraph = styled.div`
  ${customMedia.lessThan('medium')`
    margin-bottom: 20px;
    text-indent: 1rem;
  `}
  ${customMedia.greaterThan('medium')`
    margin-bottom: 40px;
    text-indent: 2rem;
  `}
`

const Signature = styled.div`
  text-align: right;
`

const Table = styled.table`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  `}

  border: 1px #1d1b1d solid;
`

const Tr = styled.tr`
  ${customMedia.lessThan('medium')`
  `}
  ${customMedia.greaterThan('medium')`
  
  `}
`

const Th = styled.th`
  ${customMedia.lessThan('medium')`
    font-size: 1rem;
    text-align: center;
    height: 27px;
    padding: 8px 7.5px 6px;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2rem;
    text-align: center;
    height: 48px;
    padding: 3px 15px 0;
  `}

  background-color: #d3d3d4;
  border: 1px #1d1b1d solid;
  font-weight: bold;
`

const Td = styled.td`
  ${customMedia.lessThan('medium')`
    font-size: 1rem;
    height: 35px;
    padding: 3px 6px 0;
  `}
  ${customMedia.greaterThan('medium')`
    font-size: 2rem;
    height: 65px;
    line-height: 2rem;
    padding: 3px 15px 0;
  `}

  border: 1px #1d1b1d solid;
`
